import axios from "axios";
import _ from "lodash";
import qs from "query-string";
import { validate as uuidValidate } from "uuid";
import { assign, createMachine, interpret } from "xstate";
import { getImageDimensions, sleep } from "./helpers/utils";

const faceApiURL = process.env.REACT_APP_FACEAPI_URL;
// const apiURL = process.env.REACT_APP_API_URL;

const debugMode = (() => {
  var debugMode = process.env.REACT_APP_DEBUG === "true";
  if (debugMode) console.log("IN DEBUG MODE");
  return debugMode;
})();

// URL params
// aid - application ID
// sid - session ID
// gid - glasses ID
// _pet - process ending type (standard / s, show_thanks / st)

function getConfigFromUrl() {
  const parsed = qs.parse(window.location.search);
  var aid = parsed.aid || null,
    sid = parsed.sid || null,
    gid = parsed.gid,
    _pet = parsed?._pet || "standard";

  switch (_pet) {
    case "standard":
    case "s":
    default:
      _pet = "standard";
      break;
    case "show_thanks_after_getting_data":
    case "show_thanks":
    case "st":
      _pet = "show_thanks";
      break;
    case "close_modal_after_rendering":
    case "ear":
      _pet = "close_modal_after_rendering";
      break;
  }

  return {
    token: sid && uuidValidate(sid) ? sid : null,
    config: {
      appId: aid,
      selectedFrame: gid || "4ca32f22-82c8-4eea-9526-2ca087480e92",
      _pet
    }
  };
}

const fetchInit = async vmContext => {
  let params = {
    sid: vmContext?.token || null
  };
  let selectedFrame;
  if ((selectedFrame = vmContext?.config?.selectedFrame))
    params.gid = selectedFrame;

  debugMode && console.log("fetchData", params);

  return await axios
    .get(`${faceApiURL}/init`, {
      crossDomain: true,
      headers: {
        Accept: "application/json"
      },
      params
    })
    .then(response => response.data);
};

var initialState = "idle",
  initialContext = {};

// var xx = JSON.parse(localStorage.getItem("loadingDataFromRemote"));
// initialState = "loadingDataFromRemote";
// initialContext = {
//   ...initialContext,
//   clientPreviewImage: xx.clientPreviewImage,
//   clientPreviewImageDimensions: xx.clientPreviewImageDimensions,
//   waitingForPrepareProcessEnded: xx.waitingForPrepareProcessEnded,
//   token: "acc4264e-a49d-421f-9247-21cbb2b94e15",
//   selectedFrame: "4ca32f22-82c8-4eea-9526-2ca087480e92"
// };

const vmMachine = createMachine({
  id: "Virtual Mirror",
  initial: initialState,
  context: initialContext,
  states: {
    /**
     * Rozpoznajemy token na jakim będziemy pracować
     * - link
     * - ze storage
     * - nowy (wygenerowany przez serwer)
     */
    idle: {
      invoke: {
        id: "getLocalToken",
        src: (context, event) => async (callback, onReceive) => {
          const { token, config } = getConfigFromUrl();

          // window.addEventListener("message", event => {
          //   const initData = event.data;
          //   if (initData.token) token = initData.token;
          //   if (initData.config) config = initData.config;
          // });

          // await sleep(500);

          // Mamy token w linku lub z message
          if (token) {
            debugMode && console.log("=> USE_EXISTING_TOKEN");
            return callback({ type: "USE_EXISTING_TOKEN", token, config });
          }

          // var token = localStorage.getItem("token");
          // if (token) return callback({ type: "USE_EXISTING_TOKEN", token });

          debugMode && console.log("=> USE_REMOTE_TOKEN");
          callback({ type: "USE_REMOTE_TOKEN", config });
        }
      },
      on: {
        /** Uzywamy istniejącego tokenu */
        USE_EXISTING_TOKEN: {
          target: "useExistingToken",
          actions: [
            assign((context, event) => {
              return {
                token: event.token,
                config: event.config
              };
            }),
            () => debugMode && console.log("=> useExistingToken")
          ]
        },
        /** Zlecamy do backednu generowanie nowego tokenu */
        USE_REMOTE_TOKEN: {
          target: "useRemoteToken",
          actions: [
            assign((context, event) => {
              return {
                config: event.config
              };
            }),
            () => debugMode && console.log("=> useRemoteToken")
          ]
        }
      },
      onEntry: [(context, event) => debugMode && console.log("*** IDLE ***")]
    },
    /**
     * Wykorzystujemy istniejący token
     */
    useExistingToken: {
      invoke: {
        id: "fetchToken",
        src: (context, event) => async (callback, onReceive) => {
          var data = await fetchInit(context);
          var forContext = {
            token: data.session
          };

          // Mamy dane nagrania ale nie wykonywaliśmy renerowania dla wskazanych okularów
          let _rpd;
          if (
            data.status === "ALMOST_READY" &&
            (_rpd = data.render_process_data) &&
            _rpd.preview
          ) {
            var previewDimensions = await getImageDimensions(_rpd.preview);

            debugMode && console.log("=> START_LOADING_DATA");
            return callback({
              type: "START_LOADING_DATA",
              clientPreviewImage: _rpd.preview,
              clientPreviewImageDimensions: previewDimensions,
              ...forContext
            });
          }

          // Mamy gotowe dane do pokazania - zakończony proces renerowania
          if (
            data.status === "READY" &&
            (_rpd = data.render_process_data) &&
            _rpd.preview
          ) {
            var previewDimensions = await getImageDimensions(_rpd.preview);

            debugMode && console.log("=> SHOW_RESULT");
            return callback({
              type: "SHOW_RESULT",
              finalData: _rpd,
              ...forContext
            });
          }

          debugMode && console.log("=> START_RECORDING");
          return callback({
            type: "START_RECORDING", // Rozpoczynamy proces nagrywania
            ...forContext
          });
        },
        onError: "fatalError"
      },
      on: {
        SHOW_RESULT: {
          target: "showPanoramas",
          actions: [
            assign((context, event) => {
              return {
                finalData: event.finalData
              };
            })
          ]
        },
        START_LOADING_DATA: {
          target: "loadingDataFromRemote",
          actions: [
            assign((context, event) => {
              return _.pickBy(event, function (o, k) {
                return k !== "type";
              });
            })
          ]
        },
        START_RECORDING: {
          target: "detectFrontCamera",
          actions: [
            assign((context, event) => {
              return _.pickBy(event, function (o, k) {
                return k !== "type";
              });
            })
          ]
        }
      },
      onEntry: [
        (context, event) => debugMode && console.log("*** useExistingToken ***")
      ]
    },
    /**
     * Token pobieramy z backendu
     */
    useRemoteToken: {
      invoke: {
        id: "fetchFrames",
        src: (context, event) => async (callback, onReceive) => {
          var data = await fetchInit(context);
          var forContext = {
            token: data.session
          };

          let _rpd;
          if (
            data.status === "READY" &&
            (_rpd = data.render_process_data) &&
            _rpd.preview
          ) {
            var previewDimensions = await getImageDimensions(_rpd.preview);

            debugMode && console.log("=> START_LOADING_DATA");
            return callback({
              type: "START_LOADING_DATA",
              clientPreviewImage: _rpd.preview,
              clientPreviewImageDimensions: previewDimensions,
              ...forContext
            });
          }

          debugMode && console.log("=> START_RECORDING");
          return callback({
            type: "START_RECORDING",
            ...forContext
          });
        },
        onError: "fatalError"
      },
      on: {
        START_LOADING_DATA: {
          target: "loadingDataFromRemote",
          actions: [
            assign((context, event) => {
              return _.pickBy(event, function (o, k) {
                return k !== "type";
              });
            })
          ]
        },
        START_RECORDING: {
          target: "detectFrontCamera",
          actions: [
            assign((context, event) => {
              return _.pickBy(event, function (o, k) {
                return k !== "type";
              });
            })
          ]
        }
      },
      onEntry: [
        (context, event) => debugMode && console.log("*** useRemoteToken ***")
      ]
    },

    initializeError: {
      type: "final",
      meta: {
        error: 1,
        message:
          "Inicjalizacja aplikacji niepowiodła się. Zgłoś się do operatora"
      }
    },

    detectFrontCamera: {
      invoke: {
        id: "detectFrontCamera",
        src: async (context, event) => {
          for (let i = 1; i <= 6; i++) {
            // Próbujemy 6x
            debugMode && console.log("CAM - Sprawdzamy dostęp do kamery");
            if (
              !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)
            ) {
              var stream = await navigator.mediaDevices.getUserMedia({
                video: {
                  width: { min: 640, ideal: 1280, max: 1920 },
                  facingMode: "user"
                },
                audio: false
              });

              if (stream.active) {
                debugMode && console.log("CAM - DONE");
                return stream;
              }

              debugMode && console.log("CAM - ponawiamy...");
              await sleep(200);
            }
          }

          return true;
        },
        onDone: {
          target: "recordingVideo",
          actions: assign({
            stream: (context, event) => {
              var stream = event.data;

              stream.getTracks().forEach(function (track) {
                debugMode && console.log(track);
                debugMode && console.log(track.getSettings());
              });

              return stream;
            }
          })
        },
        onError: "thereIsNoCamera"
      }
    },

    recordingVideo: {
      on: {
        LOADING_DONE_SHOW_TP: {
          target: "thankYou"
        },
        RELOAD_VM_STATE: {
          target: "detectFrontCamera"
        },
        RECOGNIZED_SHAPE: {
          actions: [
            assign((context, event) => {
              return {
                detectedFaceShape: event.detectedFaceShape
              };
            })
          ]
        },
        LOADING_DONE: {
          target: "showPanoramas",
          actions: [
            assign((context, event) => {
              return {
                finalData: event.finalData
              };
            })
          ]
        },
        LOADING_ERROR: {
          target: "renderError",
          actions: [
            assign((context, event) => {
              return {
                error_msg: event.error
              };
            })
          ]
        }
        // RECORDING_ALMOST_DONE: {
        //   target: "loadingDataFromRemote",
        //   actions: [
        //     assign((context, event) => {
        //       return {
        //         clientPreviewImage: event.clientPreviewImage,
        //         clientPreviewImageDimensions:
        //           event.clientPreviewImageDimensions,
        //         waitingForPrepareProcessEnded: true
        //       };
        //     })
        //   ]
        // },
        // RECORDING_DONE: {
        //   target: "loadingDataFromRemote",
        //   actions: [
        //     assign((context, event) => {
        //       // localStorage.setItem("loadingDataFromRemote", JSON.stringify({
        //       //   clientPreviewImage: event.clientPreviewImage,
        //       //   clientPreviewImageDimensions:
        //       //     event.clientPreviewImageDimensions,
        //       //   waitingForPrepareProcessEnded: false
        //       // }));

        //       return {
        //         clientPreviewImage: event.clientPreviewImage,
        //         clientPreviewImageDimensions:
        //           event.clientPreviewImageDimensions,
        //         waitingForPrepareProcessEnded: false
        //       };
        //     })
        //   ]
        // }
      }
    },

    loadingDataFromRemote: {
      on: {
        LOADING_DONE_SHOW_TP: {
          target: "thankYou"
        },
        LOADING_DONE: {
          target: "showPanoramas",
          actions: [
            assign((context, event) => {
              return {
                finalData: event.finalData
              };
            })
          ]
        },
        LOADING_ERROR: {
          target: "renderError",
          actions: [
            assign((context, event) => {
              return {
                error_msg: event.error
              };
            })
          ]
        },
        RECORDING_DONE: {
          target: "loadingDataFromRemote",
          actions: [
            assign((context, event) => {
              return {
                waitingForPrepareProcessEnded: false
              };
            })
          ]
        }
      }
    },

    showPanoramas: {
      on: {
        RELOAD_VM_STATE: {
          target: "useRemoteToken"
        },
        CHANGE_FRAME: {
          target: "loadingDataFromRemote",
          actions: [
            // (context, event) => {
            //   localStorage.setItem("lastUseFrame", event.selectedFrame);
            // },
            // assign((context, event) => {
            //   return {
            //     selectedFrame: "4ca32f22-82c8-4eea-9526-2ca087480e92"
            //   };
            // })
          ]
        }
      }
    },

    // Podziękowanie

    thankYou: {
      type: "final"
    },

    // STANY Błędne

    fatalError: {
      type: "final",
      on: {
        RELOAD_VM_STATE: {
          target: "detectFrontCamera"
        }
      }
    },

    thereIsNoCamera: {
      type: "final"
    },

    /**
     * FATAL ERROR
     */
    renderError: {
      on: {
        /**
         * Reset procesu od nowa
         * Usunięcie zapamiętanych kluczy
         */
        FORCE_START_NEW_PROCESS: {
          target: "detectFrontCamera",
          actions: [
            // assign((context, event) => {
            //   localStorage.removeItem("token");
            // })
          ]
        }
      }
    }
  }
});

const vmService = interpret(vmMachine)
  // .onTransition(state => console.log("onTransition", state))
  .start();

export { vmMachine, vmService };

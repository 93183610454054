import _ from "lodash";

export const buildForm = (object = {}) => {
  const formData = new FormData();

  for (const key in object)
    if (Array.isArray(object[key]))
      object[key].forEach(item => {
        // formData.append(key + "[]", item);
        formData.append(key, item);
      });
    else formData.append(key, object[key]);

  return formData;
};

export function b64toBlob(dataURI) {
  if (!dataURI) return;

  var byteString = atob(dataURI.split(",")[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: "image/jpeg" });
}

export function sleep(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function calculateNegativeAngleCount(array, centerValue) {
  let negativeCount = 0;

  array.forEach(item => {
    if (item < 0 && item !== centerValue) {
      negativeCount++;
    }
  });

  return negativeCount;
}

export function findClosest(goal, array) {
  let i = 0;
  let prev = Infinity;

  array.forEach((item, index) => {
    if (Math.abs(item - goal) < Math.abs(prev - goal)) {
      prev = item;
      i = index;
    }
  });

  return i;
}

export const preloadImage = src =>
  new Promise(resolve => {
    const image = new Image();
    image.src = src;
    image.addEventListener("load", () => {
      resolve(image);
    });
  });

export function getImageLightness(imageSrc, callback) {
  var img = document.createElement("img");
  img.src = imageSrc;
  img.style.display = "none";
  document.body.appendChild(img);

  var colorSum = 0;

  img.onload = function () {
    // create canvas
    var canvas = document.createElement("canvas");
    canvas.width = this.width;
    canvas.height = this.height;

    var ctx = canvas.getContext("2d");
    ctx.drawImage(this, 0, 0);

    var imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    var data = imageData.data;
    var r, g, b, avg;

    for (var x = 0, len = data.length; x < len; x += 4) {
      r = data[x];
      g = data[x + 1];
      b = data[x + 2];

      avg = Math.floor((r + g + b) / 3);
      colorSum += avg;
    }

    var brightness = Math.floor(colorSum / (this.width * this.height));
    callback(brightness);
  };
}

export function addParameterToUrl(parameter) {
  var newurl =
    window.location.protocol + "//" + window.location.host + "/" + parameter;
  window.history.pushState({ page: 1 }, "", newurl);
  window.addEventListener("popstate", function (e) {
    window.location.reload(true);
  });
}

export function preloadAudio(urls) {
  urls.forEach(url => {
    var audio = new Audio(url);
    audio.load();
  });
}

export function getPanoramaByIndex(panoramas, looking_for_frame) {
  let frames_analyze = 0;
  let response = null;

  _.each(panoramas, function (panorama, index) {
    frames_analyze += panorama.cnt;
    if (looking_for_frame < frames_analyze) {
      // wskazanie panoramy + pozycja na panoramie
      response = [index, looking_for_frame - (frames_analyze - panorama.cnt)];
      return false;
    }
  });

  return response;
}

export function getImageDimensions(src) {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.onload = () =>
      resolve({
        width: img.width,
        height: img.height
      });
    img.onerror = reject;
    img.src = src;
  });
}

export const sendMessageToParent = (event, data = null) => {
  window.parent.postMessage(
    {
      source: {
        app: "IQApp"
      },
      event,
      data
    },
    "*"
  );
};

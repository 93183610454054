import { useActor } from "@xstate/react";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import "webrtc-adapter";
import "./App.scss";
//
import { vmService } from "./iqAppMachine";
//
import StateLoadingData from "./states/LoadingData";
import StateShowPanoramas from "./states/ShowPanoramas";
import StateVideo from "./states/Video";
//
import FooterComponent from "./components/Footer";
import RateComponent from "./components/Rate";
//
import default_en from "./assets/default-en.mp3";
import sprite_default_en from "./assets/sprite-default-en";
import { stringify } from "query-string";
import { Howl, Howler } from "howler";

// =========

const apiURL = process.env.REACT_APP_API_URL;
var sound = new Howl({
  src: [default_en],
  html5: true,
  preload: true,
  sprite: sprite_default_en
});

// INICJALIZACJA APLIKACJI IQApp
// Dane wejściowe w queryparams:
// t -> token komunikacyjny
// f -> wybrana ramka do renerowania

// =====================
// Preload plików audio

// function unlockAudioContext(audioCtx) {
//   console.log("audioCtx", audioCtx);
//   if (audioCtx.state === "suspended") {
//     var events = ["touchstart", "touchend", "mousedown", "keydown"];
//     var unlock = function unlock() {
//       events.forEach(function (event) {
//         document.body.removeEventListener(event, unlock);
//       });
//       audioCtx.resume();
//     };

//     events.forEach(function (event) {
//       document.body.addEventListener(event, unlock, false);
//     });
//   }
// }

// const audioContext = new (window.AudioContext || window.webkitAudioContext)();
// unlockAudioContext(audioContext);

// function loadSound(url) {
//   var request = new XMLHttpRequest();
//   request.open("GET", url, true);
//   request.responseType = "arraybuffer";
//   request.onload = function () {
//     audioContext.decodeAudioData(
//       request.response,
//       function (buffer) {
//         // sound = buffer;
//       },
//       () => {};
//     );
//   };
//   request.send();
// }
// loadSound(audio_default)

function playSound(offset, duration) {}

// var audios = {
//   current: null
// };

// loadAudio("center", audio_center);
// loadAudio("left", audio_rotate_left);
// loadAudio("right", audio_rotate_right);
// loadAudio("done", audio_done);
// loadAudio("excelent", audio_excelent);

// =====================

// =====================
// Inne elementy

function debounce(fn, ms) {
  let timer;
  return _ => {
    clearTimeout(timer);
    timer = setTimeout(_ => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

// =====================

const App = () => {
  // State
  const [state, send] = useActor(vmService),
    { context } = state,
    { token: clientToken } = context,
    debugMode = process.env.REACT_APP_DEBUG === true;

  const [debugUrl, setDebugUrl] = useState(null);
  const [showRateQuestion, setShowRateQuestion] = useState(false);

  // Debugowanie aplikacji
  const [debugData, setDebugData] = useState([]);
  const updateDebugData = data => {
    var timestamp = parseInt(performance.now());

    if (typeof data === "string" || data instanceof String)
      data = {
        message: data
      };
    else if (typeof data === "object" && !data.action)
      data = {
        data
      };

    data["timestamp"] = timestamp;

    debugMode && console.log("Debug", data);
    setDebugData(debugData => [...debugData, data]);
  };

  // Synchronizujemy dane co X czasu
  useEffect(
    function () {
      const intervalId = setInterval(function () {
        // console.log("...", debugUrl);
        debugUrl && sendDebugDataToManagementAPI();
      }, 2500);

      return () => clearInterval(intervalId); //This is important
    },
    [debugUrl, debugData]
  );

  useEffect(
    function () {
      setDebugUrl(`${apiURL}/actions/debug?sessid=${clientToken}`);
    },
    [clientToken]
  );

  // Wysyłanie danych debugujących do Management API
  const sendDebugDataToManagementAPI = () => {
    if (debugData.length) {
      fetch(debugUrl, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(debugData)
      });

      setDebugData([]);
    }
  };

  // Wymiarowanie
  // const [dimensions, setDimensions] = useState({
  //   height: window.innerHeight,
  //   width: window.innerWidth
  // });

  useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      // setDimensions({
      //   height: window.innerHeight,
      //   width: window.innerWidth
      // });
    }, 10);

    window.addEventListener("resize", debouncedHandleResize);

    return _ => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  }, []);

  const isMobileAndLandscape = () => {
    return false; //window.innerWidth <= 768 && window.innerHeight < window.innerWidth;
  };
  // window.matchMedia("(orientation:landscape)").matches;

  // const [orientation, setOrientation] = useState(
  //   isLandscape() ? "landscape" : "portrait"
  // );
  const onWindowResize = () => {
    clearTimeout(window.resizeLag);
    window.resizeLag = setTimeout(() => {
      delete window.resizeLag;
      // setOrientation(isLandscape() ? "landscape" : "portrait");
    }, 200);
  };

  useEffect(
    () => (
      onWindowResize(),
      window.addEventListener("resize", onWindowResize),
      () => window.removeEventListener("resize", onWindowResize)
    ),
    []
  );

  useEffect(() => {
    window.addEventListener("message", event => {
      const { source, execute } = event?.data || {};

      if (
        source?.app === "eCommerceIntegrator" &&
        execute === "askForAEvaluation"
      )
        setShowRateQuestion(true);
    });
  }, []);

  const playAudio = function (audioName) {
    // updateDebugData(`*${audioName}* play state *${audios[audioName].canplay}*`);
    // playSound(2, 2); // centruj
    // playSound(0, 1); // Try again
    // playSound(1, 1); // Excelent
    // playSound(4, 2); // To see how
    // playSound(6, 2.5); // Plastic Card
    // playSound(8.5, 1); // Pstryk apartau
    // playSound(9.5, 2.5); // Back to the center
    // playSound(12, 1.5); // Left
    // playSound(13.5, 2); // Right
    // playSound(15.5, 2); // Back to the center
    // playSound(17.5, 2.5); // Back to the center

    switch (audioName) {
      case "center":
        sound.play("POSITION");
        break;
      case "left":
        sound.play("TURN_LEFT");
        break;
      case "right":
        sound.play("TURN_RIGHT");
        break;
      case "done":
        sound.play("TURN_RIGHT_TO_CENTER");
        break;
      case "excelent":
        sound.play("UPLOAD");
        break;
    }
  };

  const pauseAudio = () => {
    // @todo
  };

  const receiveSignalFromComponents = (type, ...data) => {
    // eslint-disable-next-line default-case
    switch (type) {
      //
      // VIDEO
      //
      case "RECORDING_VIDEO_ERROR":
        sound.play("ERROR_GENERIC");
        send({
          type: "LOADING_ERROR"
        });
        break;
      //
      case "RECOGNIZED_SHAPE":
        send({
          type: "RECOGNIZED_SHAPE",
          detectedFaceShape: data ? data[0] : null
        });
        break;
      //
      case "RECORDING_ALMOST_DONE":
        // playAudio("excelent");
        send({
          type: "RECORDING_ALMOST_DONE",
          clientPreviewImage: data ? data[0] : null,
          clientPreviewImageDimensions: data ? data[1] : null
        });
        break;
      //
      case "RECORDING_DONE":
        playAudio("excelent");
        send({
          type: "RECORDING_DONE",
          clientPreviewImage: data ? data[0] : null,
          clientPreviewImageDimensions: data ? data[1] : null
        });
        break;
      //
      // LOADING
      //
      case "LOADING_ERROR":
        send({
          type: "LOADING_ERROR",
          error: data[0]
        });
        break;
      //
      case "LOADING_DONE":
        send({
          type: "LOADING_DONE",
          finalData: data ? data[0] : null
        });
        break;
      case "LOADING_DONE_SHOW_TP":
        send({
          type: "LOADING_DONE_SHOW_TP"
        });
        break;
    }
  };

  // console.log("==========> RENDER");
  // console.log(state);
  // console.log(context);
  // console.log(viewMode);

  // const viewMeta = state.meta[`${vmMachine.id}.${state.value}`] || {};

  const props = {
    send,
    playAudio,
    pauseAudio,
    clientToken,
    sendSignalToVirtualMirror: receiveSignalFromComponents,
    detectedFaceShape: context.detectedFaceShape
  };

  return (
    <>
      <div className="Virtual-Mirror-App">
        <main className="Virtual-Mirror-Content">
          {(function () {
            if (isMobileAndLandscape())
              return (
                <div className="center-notification">
                  <div className="instruction">
                    Trzymaj telefon w pionie aby wykonać nagranie. Dziękujemy
                  </div>
                </div>
              );
            else if (state.matches("recordingVideo"))
              return (
                <StateVideo
                  context={context}
                  updateDebugData={updateDebugData}
                  {...props}
                />
              );
            else if (state.matches("loadingDataFromRemote"))
              return (
                <StateLoadingData
                  context={context}
                  waitingForPrepareProcessEnded={
                    context.waitingForPrepareProcessEnded === true
                  }
                  clientPreviewImage={context.clientPreviewImage}
                  dimensions={context.clientPreviewImageDimensions}
                  {...props}
                />
              );
            else if (state.matches("showPanoramas"))
              return (
                <StateShowPanoramas
                  finalData={context.finalData}
                  clientPreviewImage={context.clientPreviewImage}
                  dimensions={context.clientPreviewImageDimensions}
                  shape={
                    context.finalData?.shape_promise || context.finalData?.shape
                  }
                  {...props}
                />
              );
            else if (state.matches("detectFrontCamera"))
              return (
                <div className="center-notification">
                  <div className="instruction">
                    Zezwól aplikacji skorzystać z kamery twojego urządzenia
                  </div>
                </div>
              );
            else if (state.matches("thereIsNoCamera"))
              return (
                <div className="center-notification">
                  <div className="instruction">
                    Aplikacja nie ma dostępu do kamery
                    <div>
                      Prawodopodobnie nie wydałeś zgody na dostęp apliakcji do
                      kamery lub kamera jest wykorzystywana obecnie przez inną
                      aplikację
                    </div>
                  </div>
                </div>
              );
            else if (state.matches("fatalError"))
              return (
                <div className="center-notification">
                  <div className="instruction instruction-error">
                    Błąd połączenia z aplikacją
                    <div>
                      Wystąpił problem z połączeniem do serwera aplikacji.
                      Spróbuj ponownie
                    </div>
                  </div>
                </div>
              );
            else if (state.matches("thankYou"))
              return (
                <div className="center-notification">
                  <div className="info-message">
                    <h4>Dziękujemy za nagranie 🚀</h4>
                    <p>To wszystko ✋</p>
                  </div>
                </div>
              );
            else if (state.matches("renderError"))
              return (
                <div className="center-notification">
                  <div className="info-message">
                    <h4>We had trouble tracking your face.</h4>
                    <p>The following tips may help:</p>
                    <ul>
                      <li>Make sure you have good lighting.</li>
                      <li>Tuck your hair behind your ears.</li>
                      <li>Turn slowly when recording your video.</li>
                    </ul>
                    <button
                      onClick={() => send({ type: "FORCE_START_NEW_PROCESS" })}
                    >
                      RE-RECORD
                    </button>
                  </div>
                </div>
              );
            else
              return (
                <div className="center-notification">
                  <div className="instruction">
                    <Spinner
                      animation="border"
                      variant="info"
                      style={{
                        margin: "25px auto",
                        width: "6rem",
                        height: "6rem"
                      }}
                    />
                    <div
                      style={{
                        color: "rgb(13, 202, 240)",
                        fontWeight: "bolder"
                      }}
                    >
                      Ładowanie aplikacji...
                    </div>
                  </div>
                </div>
              );
          })()}
        </main>
        <FooterComponent token={clientToken} />
      </div>
      {showRateQuestion && (
        <RateComponent
          context={context}
          token={clientToken}
          close={() => {
            setShowRateQuestion(false);
          }}
        />
      )}
    </>
  );
};

export default App;

import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsDown,
  faThumbsUp,
  faTimes,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import { ceil } from "lodash";
import axios from "axios";
import { sendMessageToParent } from "../helpers/utils";

const apiURL = process.env.REACT_APP_API_URL;

const Rate = props => {
  const { context, token, close } = props,
    { config } = context,
    { appId } = config;
  const [step, setStep] = useState("ask");
  // Co mozemy poprawić
  const [time, setTime] = useState(false);
  const [precision, setPrecision] = useState(false);
  const [other, setOther] = useState(false);
  const [otherMessage, setOtherMessage] = useState("");

  const pressOk = () => {
    // Show thanks
    showThanksPage();
    // Send rating to API
    axios({
      url: `/session/${token}/rate`,
      method: "post",
      baseURL: apiURL,
      data: {
        rating: 1
      },
      headers: {
        Accept: "application/json",
        "App-UUID": appId // Słuzy do autoryzacji requestu
      }
    }).then(reponse => {
      parentInform();
    });
  };

  const pressNotOk = () => {
    setStep("ask_details");
  };

  const pressClose = () => {
    close();
  };

  const sendForm = () => {
    var data = {
      rating: 0,
      time: time ? 1 : 0,
      precision: precision ? 1 : 0,
      other: other ? otherMessage : null
    };

    // Show thanks
    showThanksPage();
    // Send rating to API
    axios({
      url: `/session/${token}/rate`,
      method: "post",
      baseURL: apiURL,
      data,
      headers: {
        Accept: "application/json",
        "App-UUID": appId // Słuzy do autoryzacji requestu
      }
    }).then(reponse => {
      parentInform();
    });
  };

  const showThanksPage = () => {
    setTimeout(() => {
      pressClose();
    }, 10 * 1000);
    setStep("thanks");
  };

  const handleChange = e => {
    setOtherMessage(e.target.value);
  };

  const parentInform = () => {
    sendMessageToParent({
      code: "RATED",
      message: "Rated Virtual Mirror"
    });
  };

  return (
    <div
      className="Virtual-Mirror-Rate"
      style={{
        height: step === "ask_details" ? "325px" : "90px"
      }}
    >
      <div>
        {step === "ask" && (
          <>
            Jak oceniasz Naszą Witrtualną przymierzalnię?
            <span className="thumbs-icon icon-green" onClick={pressOk}>
              <FontAwesomeIcon icon={faThumbsUp} />
            </span>
            <span className="thumbs-icon icon-grey" onClick={pressNotOk}>
              <FontAwesomeIcon icon={faThumbsDown} />
            </span>
          </>
        )}
        {step === "thanks" && <>Dziękujemy za opinię!</>}
        {step === "ask_details" && (
          <div className="ask-form">
            <div>Co Twoim zdaniem moglibyśmy poprawić?</div>
            <div className="vm-top-marg">
              <label className="vm-cbx-container">
                Czas
                <input
                  type="checkbox"
                  defaultChecked={time}
                  onChange={() => setTime(!time)}
                />
                <span className="vm-cbx-checkmark"></span>
              </label>
            </div>
            <div className="vm-top-marg">
              <label className="vm-cbx-container">
                Dokładność
                <input
                  type="checkbox"
                  defaultChecked={precision}
                  onChange={() => setPrecision(!precision)}
                />
                <span className="vm-cbx-checkmark"></span>
              </label>
            </div>
            <div className="vm-top-marg">
              <label className="vm-cbx-container">
                Inne (jakie?)
                <input
                  type="checkbox"
                  defaultChecked={other}
                  onChange={() => setOther(!other)}
                />
                <span className="vm-cbx-checkmark"></span>
              </label>
            </div>
            <div className="vm-top-marg">
              <input
                type="text"
                name="other_message"
                disabled={other === false}
                defaultValue={otherMessage}
                onChange={handleChange}
              />
            </div>
            <div
              className="vm-top-marg"
              style={{
                textAlign: "center"
              }}
            >
              <button onClick={sendForm}>Wyślij</button>
            </div>
          </div>
        )}

        <span className="vm-close-icon" onClick={pressClose}>
          <FontAwesomeIcon icon={faTimes} />
        </span>
      </div>
    </div>
  );
};

export default Rate;

import axios from "axios";
import React from "react";
import Webcam from "react-webcam";
import record_overlay_grey from "../assets/record-overlay-2.svg";
import record_overlay from "../assets/record-overlay.svg";
import { uploadFrames, uploadPreviewPhoto } from "../utils/upload";
import { buildForm, sleep } from "../helpers/utils";
import StateLoadingData from "./LoadingData";
import { Spinner } from "react-bootstrap";

const faceApiURL = process.env.REACT_APP_FACEAPI_URL,
  apiURL = process.env.REACT_APP_API_URL;

var countdownIntervals = {},
  takeInterval;

// Component modes
const CMP_MODE_WAITING_FOR_START = "WAITING_FOR_START";
const CMP_MODE_WAITING_FOR_RECORDING_START = "WAITING_FOR_RECORDING_START";
const CMP_MODE_RECORDING_IN_PROGRESS = "RECORDING_IN_PROGRESS";
const CMP_MODE_RECORDING_DONE = "CMP_MODE_RECORDING_DONE";
const CMP_MODE_WAITING_FOR_PREPARE_DATA = "CMP_MODE_WAITING_FOR_PREPARE_DATA";
// const CMP_MODE_WAITING_FOR_PHOTO_CC = "WAITING_FOR_PHOTO_CC";
const CMP_MODE_DONE = "CMP_MODE_DONE";
const CMP_MODE_PREPARE_ERROR = "CMP_MODE_DONE";

class Video extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mode: CMP_MODE_WAITING_FOR_START,
      modeAttts: {
        instruction: "Kliknij start, aby rozpocząć nagrywanie"
      },
      width: null,
      height: null,
      camera: null,
      //
      takeFrames: [], // Klatki odebrane od klienta
      grabbedFrames: [], // Klatki takeFrames[] + obróbka - gotowe do wysłania
      grabbedRequests: [], // Requesty wysłane do S3
      grabbedFramesStats: {
        // Statystyki grabowania
        init: 0,
        done: 0,
        sent: 0
      },
      // takeFrameAnalyze: 0, // klatka analizowana
      uploads: {
        // photo_cc: false,
        preview: null
      },
      showRotateAnimation: false
    };

    this.webcamRef = React.createRef();
  }

  isMobile = () =>
    window.matchMedia && window.matchMedia("(max-width: 600px)").matches;

  // Zapisywanie danych debugowania do state
  debug = data => {
    this.props.updateDebugData(data);
  };

  // Mount && Unmount
  componentDidMount() {
    this.detectFrontCamera();
  }

  componentWillUnmount() {
    //   clearInterval(takeInterval);
    for (const property in countdownIntervals) {
      //    clearInterval(countdownIntervals[property]);
    }
  }

  // Zmiana trybu komponentu
  changeComponentMode = (mode, states, callback) => {
    this.debug({
      action: "CHANGE MODE",
      data: {
        mode
      }
    });

    this.setState(
      {
        mode,
        ...states
      },
      callback
    );
  };

  // Sprawdzenie trybu komponentu
  checkComponentMode = mode => {
    return this.state.mode === mode;
  };

  markRecordingAsDone = () => {
    this.changeComponentMode(CMP_MODE_DONE);
  };

  detectFrontCamera = async () => {
    var { stream } = this.props.context;

    const { clientToken } = this.props;
    // constraints = {
    //   audio: false,
    //   video: {
    //     width: { min: 640, ideal: 1280, max: 1920 },
    //     facingMode: "user"
    //   }
    // };

    let batches = {};

    // let devices = await navigator.mediaDevices
    //   .enumerateDevices()
    //   .then(function (e) {
    //     return e
    //       .filter(function (e) {
    //         return "videoinput" === e.kind;
    //       })
    //       .map(function (e) {
    //         return {
    //           id: e.deviceId,
    //           label: e.label,
    //           userFacing: !!e.facingMode && e.facingMode.indexOf("user") > -1
    //         };
    //       });
    //   })
    //   .catch(function (e) {
    //     return e;
    //   });
    // console.log(devices);

    batches["steamsInfo"] = {
      streams_counts: stream.getVideoTracks().length
    };

    let stream_device = stream.getVideoTracks()[0];
    let stream_settings = stream_device.getSettings();

    stream.getVideoTracks().forEach((stream_device, key) => {
      let stream_settings = stream_device.getSettings();
      batches[`steam_${key + 1}`] = {
        id: stream_device.id,
        label: stream_device.label,
        aspectRatio: stream_device.aspectRatio,
        frameRate: stream_device.frameRate,
        resolution: `${stream_settings.width}x${stream_settings.height}`
      };
    });

    var cameraSettings = {
      id: stream_device.id,
      name: stream_device.label,
      aspectRatio: stream_settings.aspectRatio,
      frameRate: stream_settings.frameRate,
      resolution: {
        width: stream_settings.width,
        height: stream_settings.height
      }
    };

    const videoBoxSettings = this.getVideoBoxSettings(
      cameraSettings.resolution
    );

    batches["cameraResolution"] = cameraSettings.resolution;

    // console.log("Your camera settings", cameraSettings, videoBoxSettings);

    fetch(`${apiURL}/actions/saveData?sessid=${clientToken}`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json"
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify({
        batches
      })
    });

    this.setState({
      width: videoBoxSettings.width || cameraSettings.resolution.width,
      height: videoBoxSettings.height || cameraSettings.resolution.height,
      camera: cameraSettings
    });
  };

  handleDataAvailable = ({ data }) => {
    if (data.size > 0) {
      this.setState(prevState => ({
        recordedChunks: prevState.recordedChunks.concat(data)
      }));
    }
  };

  // startTakePhotoProcess = () => {
  //   this.changeComponentMode(CMP_MODE_WAITING_FOR_PHOTO_CC, {
  //     modeAttts: {
  //       instruction: "Przyłóż kartę do czoła i wykonaj zdjęcie"
  //     }
  //   });
  // };

  // skipPhoto = () => {
  //   this.initializeProcessing();
  // };

  // Problem z analizą kształtu twarzy
  analyzePreviewPhotoError = e => {
    // console.log("analyzePreviewPhotoError", e);
    // this.props.sendSignalToVirtualMirror(
    //   "RECORDING_VIDEO_ERROR",
    //   "Nie udało się zebrać danych."
    // );
  };

  // Problem z analizą danych zebranych w procesie
  fatalError = e => {
    // Przerwanie działania aplikacji
    this.setState(
      {
        mode: CMP_MODE_PREPARE_ERROR
      },
      () => {
        this.props.sendSignalToVirtualMirror(
          "RECORDING_VIDEO_ERROR",
          "Nie udało się zebrać danych."
        );
      }
    );
  };

  // WYkonujemy zdjęcia podglądowe
  takePreviewPhoto = (timeInterval = 0) => {
    countdownIntervals.preview = setTimeout(() => {
      const that = this;

      var screenshot = this.webcamRef.current.getScreenshot();

      var image = new Image();
      image.onload = function () {
        const { height, width } = image,
          targetCropSize = 720;

        var canvas = document.createElement("canvas");
        canvas.setAttribute("width", targetCropSize);
        canvas.setAttribute("height", targetCropSize);
        var canvasCtx = canvas.getContext("2d");

        // Róznica wielkości pierwotnego obrazu z docelowych
        var yDiff = targetCropSize - height,
          xDiff = targetCropSize - width;

        var mnoznik = Math.max(yDiff / height, xDiff / width, 0);
        var iW = width * (1 + mnoznik),
          iH = height * (1 + mnoznik);

        canvasCtx.drawImage(
          image,
          // Source image od 0,0 do końca
          0,
          0,
          width,
          height,
          // Rozciąganie zdjęcia do rozmiaru 1280x720
          (targetCropSize - Math.max(targetCropSize, iW)) / 2,
          (targetCropSize - Math.max(targetCropSize, iH)) / 2,
          iW,
          iH
        );

        canvas.toBlob(
          function (blob) {
            var reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = function () {
              const { clientToken } = that.props;
              var base64data = reader.result;

              uploadPreviewPhoto(clientToken, blob, () => {
                that.setState(prevState => ({
                  uploads: {
                    ...prevState.uploads,
                    preview: base64data
                  }
                }));
              }).then(value => {
                if (value) that.analyzePreviewPhoto();
              });
            };
          },
          "image/jpeg",
          1
        );
      };
      image.src = screenshot;
    }, timeInterval);
  };

  analyzePreviewPhoto = () => {
    const { clientToken } = this.props;
    const url = `${faceApiURL}/get/${clientToken}/shape`;

    try {
      const sse = new EventSource(url);

      sse.onerror = event => {
        // @todo - Error / obsłuzyć to
        // console.log("/shape [error]", event.data);

        sse.close();
        this.analyzePreviewPhotoError({
          type: "undefinedError",
          data: event.data
        });
      };

      sse.onmessage = event => {
        // @todo - Niezidentyfikowana wiadomość / obsłuzyć to
        // console.log("/shape [message]", event.data);
      };

      sse.addEventListener("apperror", async e => {
        sse.close();
        const data = JSON.parse(e.data),
          { error } = data;
        // ex. {"errorCode": 510, "error": "Face not found", "step": "In prepare"}
        if (error) this.analyzePreviewPhotoError(data);
      });

      sse.addEventListener("fatalerror", async e => {
        sse.close();
        const data = JSON.parse(e.data);
        this.analyzePreviewPhotoError(data);
      });

      sse.addEventListener("renderShape", async e => {
        const data = JSON.parse(e.data);
        const { shape } = data;
        // shape &&
        //   this.props.sendSignalToVirtualMirror("RECOGNIZED_SHAPE", shape);
      });

      sse.addEventListener("renderShapeDone", async e => {
        const data = JSON.parse(e.data);
        const { shape, shape_promise } = data;

        shape &&
          this.props.sendSignalToVirtualMirror(
            "RECOGNIZED_SHAPE",
            shape_promise || shape
          );

        // shape &&
        // this.props.sendSignalToVirtualMirror("RECOGNIZED_SHAPE", shape);

        sse.close();
      });
    } catch (e) {
      this.analyzePreviewPhotoError(e);
    }
  };

  // 2.5 sek po "center"
  startRecordingData = () => {
    // 3.0 sek po "center"
    countdownIntervals.canvasFaceMask_l = setTimeout(() => {
      this.playAudio("left");
      this.debug("Obróć głowe w lewo");
      this.changeComponentMode(CMP_MODE_RECORDING_IN_PROGRESS, {
        modeAttts: {
          instruction: "Obróć głowe w lewo"
        }
      });
    }, 500);

    // 5.5 sek po "center"
    setTimeout(() => {
      this.debug("Obróć głowe w prawo");
      this.setInstruction("Obróć głowe w prawo");
      this.playAudio("right");
      setTimeout(() => {
        // Take frames
        var takeFrameTimeout = 250;
        // Czas na nagrywanie 4.5 sek - 250ms
        // ... interval 100ms -> ~40 klatek
        takeInterval = setInterval(() => {
          this.takeFrame();
        }, takeFrameTimeout);
      }, 150);
    }, 3000);

    // 10.5 sek po "center"
    countdownIntervals.canvasFaceMask_c = setTimeout(() => {
      this.debug("Wystarczy wyprostuj głowe");
      this.playAudio("done");
      this.changeComponentMode(
        CMP_MODE_RECORDING_DONE,
        {
          modeAttts: {
            instruction: "Wystarczy wyprostuj głowe"
          }
        },
        () => {
          this.checkGrabbedFramesReadyToSent();

          var { grabbedFrames, grabbedFramesStats } = this.state;
          if (grabbedFramesStats.done === grabbedFramesStats.sent) {
            if (grabbedFrames.length > 0) this.sendFramesToAPI();
            else this.checkGrabbedProcessIsEnded();
          }
        }
      );
    }, 7500);

    countdownIntervals.canvasFaceMask_e = setTimeout(() => {
      try {
        clearInterval(countdownIntervals.canvasFaceMask);
        if (this.state.mode !== CMP_MODE_PREPARE_ERROR) {
          this.playAudio("excelent");
          this.changeComponentMode(
            CMP_MODE_WAITING_FOR_PREPARE_DATA,
            {
              modeAttts: {
                instruction: null
              },
              showRotateAnimation: false
            },
            () => {
              this.checkGrabbedFramesReadyToSent();
            }
          );
        }
      } catch (e) {
        this.pauseAudio();
        this.fatalError(e);
      }
    }, 10000);
  };

  handleStartRecordingVideo = () => {
    this.changeComponentMode(
      CMP_MODE_WAITING_FOR_RECORDING_START,
      {
        modeAttts: {
          instruction: `Nagrywanie za chwilę się rozpocznie`
        },
        showRotateAnimation: true
      },
      () => {
        this.playAudio("center");
        this.takePreviewPhoto(2150);

        setTimeout(() => {
          this.startRecordingData();
        }, 2500);
      }
    );
  };

  setInstruction = instruction => {
    this.setState(prevState => {
      return {
        modeAttts: {
          ...prevState.modeAttts,
          instruction
        }
      };
    });
  };

  // Odtwarzanie nagrania audio
  playAudio = audio_file_name => {
    this.props.playAudio(audio_file_name);
  };

  // Pauza na nagraniu audio
  pauseAudio = () => {
    this.props.pauseAudio();
  };

  clickDebug = async () => {
    // var screenshot = this.webcamRef.current.getScreenshot();
    // console.log(this.webcamRef.current);

    var stream = this.webcamRef.current.stream;

    stream.getTracks().forEach(function (track) {
      // console.log(track);
      // console.log(track.getSettings());
    });
  };

  // Pobieranie klatki od klienta
  takeFrame = async () => {
    if (!this.checkComponentMode(CMP_MODE_RECORDING_IN_PROGRESS)) return;

    var that = this,
      frame_id = Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(0, 10)
        .toUpperCase();

    // Screenshot

    var screenshot = this.webcamRef.current.getScreenshot(),
      msTime = parseInt(performance.now()),
      frame_start_time = msTime;

    this.setState({
      takeFrames: [
        ...this.state.takeFrames,
        {
          ts: msTime,
          screenshot
        }
      ],
      grabbedFramesStats: {
        ...this.state.grabbedFramesStats,
        init: this.state.grabbedFramesStats.init + 1
      }
    });
    // ==================
    // Oznaczenie rozpoczęcia pobierania ramki
    this.debug({
      action: "TAKE FRAME START",
      data: {
        process_id: frame_id
      }
    });

    // Analyze

    var image = new Image();
    image.onload = function () {
      const { height, width } = image,
        targetCropSize = 720;

      var canvas = document.createElement("canvas");
      canvas.setAttribute("width", targetCropSize);
      canvas.setAttribute("height", targetCropSize);
      var canvasCtx = canvas.getContext("2d");

      // Róznica wielkości pierwotnego obrazu z docelowych
      var yDiff = targetCropSize - height,
        xDiff = targetCropSize - width;

      var mnoznik = Math.max(yDiff / height, xDiff / width, 0);
      var iW = width * (1 + mnoznik),
        iH = height * (1 + mnoznik);

      canvasCtx.drawImage(
        image,
        // Source image od 0,0 do końca
        0,
        0,
        width,
        height,
        // Rozciąganie zdjęcia do rozmiaru 1280x720
        (targetCropSize - Math.max(targetCropSize, iW)) / 2,
        (targetCropSize - Math.max(targetCropSize, iH)) / 2,
        iW,
        iH
      );

      canvas.toBlob(
        blob => {
          var { grabbedFrames } = that.state;

          const frameName = String(msTime).padStart(6, "0"),
            fileName = `src_${frameName}.jpg`;

          grabbedFrames.push({
            blob,
            fileName
          });

          this.setState(
            {
              grabbedFrames,
              grabbedFramesStats: {
                ...that.state.grabbedFramesStats,
                done: that.state.grabbedFramesStats.done + 1
              }
            },
            () => {
              that.checkGrabbedFramesReadyToSent();
            }
          );

          // ==================
          // Oznaczenie rozpoczęcia pobierania ramki
          this.debug({
            action: "TAKE FRAME DONE",
            data: {
              process_id: frame_id,
              ms: parseInt(performance.now()) - frame_start_time
            }
          });
        },
        "image/jpeg",
        1
      );
    }.bind(this);
    image.src = screenshot;
  };

  // Sprawdzamy czy dane nadają się do wysłania na serwer
  checkGrabbedFramesReadyToSent = (last_run = false) => {
    var { grabbedFrames, grabbedFramesStats, takeFrames, mode } = this.state;

    var debugData = {
      action: "CHECK",
      message: "Sprawdzamy czy mamy coś do wysłania na serwer",
      data: {
        gfs: grabbedFramesStats,
        takeFrames: takeFrames.length,
        grabbedFrames: grabbedFrames.length,
        mode,
        isOK: false
      }
    };

    // Stan CMP_MODE_RECORDING_DONE aplikacja osiąga po "Wystarczy wyprostuj głowe"
    // Stan CMP_MODE_WAITING_FOR_PREPARE_DATA aplikacja otrzymuje przy zapowiedzi excelent

    if (
      grabbedFrames.length >= 8 ||
      ((this.checkComponentMode(CMP_MODE_RECORDING_DONE) ||
        this.checkComponentMode(CMP_MODE_WAITING_FOR_PREPARE_DATA)) &&
        grabbedFrames.length > 0)
    ) {
      debugData["data"]["isOK"] = true;
      this.sendFramesToAPI();
    }

    this.debug(debugData);
  };

  sendFramesToAPI = () => {
    var { clientToken } = this.props;
    var { grabbedFrames, grabbedRequests } = this.state;
    if (grabbedFrames.length === 0) return;

    var sentFramesCount = grabbedFrames.length;
    var request = uploadFrames(
      clientToken,
      grabbedFrames,
      grabbedRequests.length + 1
    );

    this.debug({
      action: "SENT FRAMES",
      message: "Sent frames to Backend",
      data: {
        cnt: sentFramesCount
      }
    });

    grabbedRequests.push(request);
    grabbedFrames = [];

    this.setState(
      {
        grabbedFrames,
        grabbedRequests,
        grabbedFramesStats: {
          ...this.state.grabbedFramesStats,
          sent: this.state.grabbedFramesStats.sent + sentFramesCount
        }
      },
      () => {
        this.checkGrabbedProcessIsEnded();
      }
    );
  };

  endedGrabbedProcess_retry = () => {
    const that = this;
    var { takeFrames, grabbedFramesStats } = that.state;

    that.debug("grabbedRequests -> init: " + grabbedFramesStats.init);
    that.debug("grabbedRequests -> done: " + grabbedFramesStats.done);
    that.debug("grabbedRequests -> takeFrames: " + takeFrames.length);
    // that.debug("grabbedRequests -> takeFrameAnalyze: " + takeFrameAnalyze);
    if (grabbedFramesStats.done < grabbedFramesStats.init) {
      that.debug("grabbedRequests -> retry");
      return setTimeout(function () {
        that.endedGrabbedProcess_retry();
      }, 500);
    } else that.endedGrabbedProcess_done();
  };

  checkGrabbedProcessIsEnded = () => {
    var { grabbedFramesStats } = this.state;

    if (
      grabbedFramesStats.done == grabbedFramesStats.init &&
      grabbedFramesStats.done == grabbedFramesStats.sent &&
      (this.checkComponentMode(CMP_MODE_RECORDING_DONE) ||
        this.checkComponentMode(CMP_MODE_WAITING_FOR_PREPARE_DATA))
    )
      this.endedGrabbedProcess_done();
  };

  endedGrabbedProcess_done = () => {
    var { grabbedRequests, grabbedFramesStats } = this.state;

    Promise.all(grabbedRequests).then(values => {
      this.debug({
        action: "UPLOAD DONE",
        message: "UPLOAD DONE",
        data: grabbedFramesStats
      });
      this.prepareData();
    });
  };

  prepareData = () => {
    const { clientToken } = this.props,
      url = `${faceApiURL}/get/${clientToken}/prepare`;

    this.debug("prepareData", true);

    try {
      const sse = new EventSource(url);

      sse.onerror = event => {
        // @todo - Error / obsłuzyć to
        this.debug("/prepare [error]", event.data);

        sse.close();
        this.fatalError({
          type: "undefinedError",
          data: event.data
        });
      };

      sse.onmessage = event => {
        // @todo - Niezidentyfikowana wiadomość / obsłuzyć to
        this.debug("/prepare [message]", event.data);
      };

      sse.addEventListener("apperror", async e => {
        sse.close();
        const data = JSON.parse(e.data),
          { error } = data;
        // ex. {"errorCode": 520, "error": "Valid faces not found", "step": "In prepare"}
        if (error) this.fatalError(data);
        else this.markRecordingAsDone();
      });

      sse.addEventListener("fatalerror", async e => {
        sse.close();
        const data = JSON.parse(e.data);
        this.fatalError(data);
      });

      sse.addEventListener("prepareDone", async e => {
        sse.close();
        this.markRecordingAsDone();
      });
    } catch (e) {
      this.fatalError(e);
    }
  };

  // takePhoto = () => {
  //   const { width, height } = this.state;

  //   try {
  //     const uploadType = "photo_cc";
  //     const screenshot = this.webcamRef.current.getScreenshot({
  //       width,
  //       height
  //     });
  //     const uploadContent = b64toBlob(screenshot);
  //     if (!uploadContent) throw new Error();

  //     this.uploadDataToRemote(
  //       uploadType,
  //       uploadContent,
  //       true,
  //       this.initializeProcessing
  //     );
  //   } catch (e) {
  //     // CL
  //   }
  // };

  // /**
  //  * Informujemy rodzica, ze moze rozpoczynac proces renderowania
  //  */
  // initializeProcessing = () => {
  //   this.props.sendSignalToVirtualMirror(
  //     !this.checkComponentMode(CMP_MODE_DONE)
  //       ? "RECORDING_ALMOST_DONE"
  //       : "RECORDING_DONE",
  //     this.state.uploads.preview,
  //     {
  //       width: this.state.width,
  //       height: this.state.height
  //     }
  //   );
  // };

  clearCanvas(context, canvas) {
    context.clearRect(0, 0, canvas.width, canvas.height);
    const w = canvas.width;
    canvas.width = 1;
    canvas.width = w;
  }

  modeIsWaitingForStart = () => {
    return this.checkComponentMode(CMP_MODE_WAITING_FOR_START);
  };

  modeIsWaitingForRecording = () => {
    return (
      !this.modeIsWaitingForStart() &&
      this.checkComponentMode(CMP_MODE_WAITING_FOR_RECORDING_START)
    );
  };

  modeIsRedordingInProgress = () => {
    return (
      !this.modeIsWaitingForStart() &&
      this.checkComponentMode(CMP_MODE_RECORDING_IN_PROGRESS)
    );
  };

  getVideoBoxSettings = ({ width, height }) => {
    var sWidth = width,
      sHeight = height,
      portrait = sHeight > sWidth;
    var showTopInstruction =
      this.modeIsWaitingForStart() ||
      this.modeIsWaitingForRecording() ||
      this.modeIsRedordingInProgress();

    var renderBox = document.getElementsByClassName(
        "Virtual-Mirror-Content"
      )[0],
      renderBoxHeight = renderBox.offsetHeight,
      renderBoxWidth = renderBox.offsetWidth;

    renderBoxWidth = renderBoxWidth - (renderBoxWidth % 4);

    // Brak instrukcji na małych ekranach
    if (renderBoxHeight < 500) showTopInstruction = false;
    const maxAllowH = renderBoxHeight - (showTopInstruction ? 80 : 0);

    height = Math.round(Math.min(maxAllowH, 360));
    height = height - (height % 2);
    width = Math.round(sWidth * (height / sHeight));
    width = width - (width % 2);

    var widthOnScreen = Math.max(width, renderBoxWidth);

    return {
      renderBox,
      height,
      width: widthOnScreen,
      showTopInstruction,
      portrait
    };
  };

  render() {
    const { mode, modeAttts, showRotateAnimation, height } = this.state;

    if (mode === CMP_MODE_WAITING_FOR_PREPARE_DATA || mode === CMP_MODE_DONE)
      return (
        <StateLoadingData
          context={this.props.context}
          waitingForPrepareProcessEnded={
            !this.checkComponentMode(CMP_MODE_DONE)
          }
          clientPreviewImage={this.state.uploads.preview}
          dimensions={{
            width: this.state.width,
            height: this.state.height
          }}
          {...this.props}
        />
      );

    if (!this.state.width || !this.state.height)
      return (
        <div className="center-notification">
          <div className="instruction">
            <Spinner
              animation="border"
              variant="info"
              style={{
                margin: "25px auto",
                width: "6rem",
                height: "6rem"
              }}
            />
            <div
              style={{
                color: "rgb(13, 202, 240)",
                fontWeight: "bolder"
              }}
            >
              Przygotowanie aplikacji do nagrania...
            </div>
          </div>
        </div>
      );

    const {
      renderBox,
      width,
      // height,
      showTopInstruction,
      portrait
    } = this.getVideoBoxSettings(this.state.camera.resolution);

    // Nakłada na obraz overlay w rozmiarze 150x150
    // po bokach do tego dokłada dodatkowe szare prostokąty

    var maxWidth = Math.min(width, window.innerWidth);
    var maxHeight = Math.min(height, renderBox.offsetHeight);

    var maskSize = Math.min(width, height, 350);
    if (maskSize % 2 !== 0) maskSize = maskSize - (maskSize % 2);
    var wO = maxWidth - maskSize,
      hO = maxHeight - maskSize;

    const { width: cameraW, height: cameraH } = this.state.camera.resolution;

    var videoScale;
    if (!portrait) videoScale = width / this.state.camera.resolution.width;
    else videoScale = 0.35;

    // Obliczanie pozycji dla video
    var videoLeft = (width - cameraW) / 2,
      videoTop = (height - cameraH) / 2;

    var maxRecordingWidth = Math.min(window.innerWidth, 640), // Nie więcej niz 640px
      maxRecordingHeight = Math.max(
        360,
        Math.min(
          renderBox.offsetHeight,
          cameraH > cameraW && cameraH / cameraW > 1.7 ? 450 : 0
        )
      );

    var videoWidth = this.state.camera.resolution.width, // 1280
      videoHeight = this.state.camera.resolution.height; // 720

    var oldVideoWidth = videoWidth,
      oldVideoHeight = videoHeight;
    videoWidth = Math.min(maxRecordingWidth, videoWidth);
    videoHeight = (videoWidth * oldVideoHeight) / oldVideoWidth;

    if (videoHeight < maxRecordingHeight) {
      videoHeight = maxRecordingHeight;
      videoWidth = (videoHeight * oldVideoWidth) / oldVideoHeight;
    }

    videoScale = 1; //maxRecordingWidth / videoWidth
    // 1280 / 640 = 0.5
    videoLeft = (maxRecordingWidth - videoWidth) / 2; // -320
    videoTop = (maxRecordingHeight - videoHeight) / 2; // -320

    wO = maxRecordingWidth - maskSize;
    hO = maxRecordingHeight - maskSize;

    const showBlackOverlay = !this.isMobile();

    return (
      <>
        {showTopInstruction && this.modeIsWaitingForStart() && (
          <div className="visual-component-instr">
            Wykonaj nagranie swojej twarzy, odsłoń uszy i patrz w kamerę
          </div>
        )}
        {showTopInstruction &&
          (this.modeIsWaitingForRecording() ||
            this.modeIsRedordingInProgress()) && (
            <div className="visual-component-instr">
              <div class="l-Standard-indicator TickScale">
                <div class="TickScale-left">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="Ticks"
                    viewBox="0 0 155 20"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="100%"
                      height="100%"
                      fill="url(#Tick)"
                    ></rect>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="Ticks Ticks-hilite Ticks-left"
                    viewBox="0 0 155 20"
                  >
                    <mask id="hilite-mask-1">
                      <rect
                        class="Ticks-animate Ticks-animate-left"
                        x="0"
                        y="0"
                        width="155"
                        height="20"
                        fill="#fff"
                      ></rect>
                    </mask>
                    <rect
                      x="0"
                      y="0"
                      width="100%"
                      height="100%"
                      fill="url(#Tick-hilite)"
                      mask="url(#hilite-mask-1)"
                    ></rect>
                  </svg>
                </div>
                <div class="TickScale-right">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="Ticks"
                    viewBox="0 0 155 20"
                  >
                    <rect
                      x="0"
                      y="0"
                      width="100%"
                      height="100%"
                      fill="url(#Tick)"
                    ></rect>
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="Ticks Ticks-hilite Ticks-right"
                    viewBox="0 0 155 20"
                  >
                    <mask id="hilite-mask-3">
                      <rect
                        class="Ticks-animate Ticks-animate-right"
                        x="0"
                        y="0"
                        width="155"
                        height="20"
                        fill="#fff"
                      ></rect>
                    </mask>
                    <rect
                      x="0"
                      y="0"
                      width="100%"
                      height="100%"
                      fill="url(#Tick-hilite)"
                      mask="url(#hilite-mask-3)"
                    ></rect>
                  </svg>
                </div>
                <div class="TickScale-center TickScale-static"></div>
                <div class="TickScale-center TickScale-indicator"></div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Ticks"
                  viewBox="0 0 200 10"
                >
                  <defs>
                    <pattern
                      id="Tick"
                      x="0"
                      y="0"
                      width="10"
                      height="10"
                      patternUnits="userSpaceOnUse"
                    >
                      <line id="Tick-line" x1="0" x2="0" y1="0" y2="10"></line>
                    </pattern>
                    <pattern
                      id="Tick-hilite"
                      x="0"
                      y="0"
                      width="10"
                      height="10"
                      patternUnits="userSpaceOnUse"
                    >
                      <line
                        id="Tick-hilite-line"
                        x1="0"
                        x2="0"
                        y1="0"
                        y2="10"
                      ></line>
                    </pattern>
                  </defs>
                </svg>
              </div>
            </div>
          )}
        <div
          className={`visual-component ${
            !showTopInstruction && "visual-component--without-inst"
          }`}
        >
          <div
            style={{
              position: "relative",
              overflow: "hidden",
              height: maxRecordingHeight,
              width: maxRecordingWidth
            }}
            onClick={() => {
              this.clickDebug();
            }}
          >
            {showRotateAnimation && (
              <div
                class="MoveAnimation-wrap"
                style={{
                  zIndex: 10,
                  height: maskSize + "px",
                  width: maskSize + "px",
                  top: hO / 2,
                  left: wO / 2,
                  transform: "scaleY(0.678) scaleX(0.51)"
                }}
              >
                <div
                  class="MoveAnimation is-animating "
                  style={{
                    height: maskSize + "px",
                    width: maskSize + "px"
                  }}
                >
                  <div class="MoveAnimation-line"></div>
                  <div
                    class="MoveAnimation-arrow"
                    style={{
                      transform: "translate(100%)"
                    }}
                  ></div>
                </div>
              </div>
            )}
            {showBlackOverlay && (
              <>
                <div
                  className={`mask mask-ol-z`}
                  style={{
                    backgroundImage: `url(${record_overlay})`,
                    backgroundSize: `${maskSize}px ${maskSize}px`,
                    backgroundRepeat: "no-repeat",
                    opacity: "0.5",
                    // width,
                    height
                  }}
                ></div>
                {/* Lewa strona */}
                <div
                  className="mask-ol-z"
                  style={{
                    position: "absolute",
                    backgroundImage: `url(${record_overlay_grey})`,
                    backgroundSize: `150px 150px`,
                    left: "0",
                    top: "0",
                    width: wO / 2,
                    height
                  }}
                ></div>
                {/* Prawa strona */}
                <div
                  className="mask-ol-z"
                  style={{
                    position: "absolute",
                    backgroundImage: `url(${record_overlay_grey})`,
                    backgroundSize: `150px 150px`,
                    right: "0",
                    top: "0",
                    width: wO / 2,
                    height
                  }}
                ></div>
                {/* Gora */}
                <div
                  className="mask-ol-z"
                  style={{
                    position: "absolute",
                    backgroundImage: `url(${record_overlay_grey})`,
                    backgroundSize: `150px 150px`,
                    left: wO / 2,
                    top: 0,
                    width: maskSize,
                    height: hO / 2
                  }}
                ></div>
                {/* Dol */}
                <div
                  className="mask-ol-z"
                  style={{
                    position: "absolute",
                    backgroundImage: `url(${record_overlay_grey})`,
                    backgroundSize: `150px 150px`,
                    right: wO / 2,
                    bottom: "0",
                    width: maskSize,
                    height: hO / 2
                  }}
                ></div>
              </>
            )}
            {/* Show instructions */}
            {modeAttts && modeAttts.instruction && (
              <div
                style={{
                  position: "absolute",
                  zIndex: 50,
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "100%",
                  height: "100%",
                  maxWidth: width,
                  top: 0
                }}
              >
                <div
                  style={{
                    maxWidth: width
                  }}
                  className="alert-on-top"
                  role="alert"
                >
                  {modeAttts.instruction}
                </div>
              </div>
            )}
            {/* Wymiarowanie za pomocą obiektu */}
            {this.modeIsWaitingForStart() && (
              <div
                style={{
                  position: "absolute",
                  zIndex: 50,
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "100%",
                  height: "100%",
                  maxWidth: width,
                  top: 0
                }}
              >
                {this.state.instruction && (
                  <div
                    style={{
                      maxWidth: width
                    }}
                    className="alert-on-top"
                    role="alert"
                  >
                    {this.state.instruction}
                  </div>
                )}
                <div style={{ clear: "both" }}></div>
                <div className="take-photo-actions">
                  <button
                    onClick={this.handleStartRecordingVideo}
                    className="take-photo-actions__btn"
                    style={{
                      // margin: '10px',
                      minHeight: "auto"
                    }}
                  >
                    {/* <FaPlay /> */}
                    START
                  </button>
                </div>
              </div>
            )}
            {/* Wymiarowanie za pomocą obiektu */}
            {/* {this.checkComponentMode(CMP_MODE_WAITING_FOR_PHOTO_CC) && (
              <div
                style={{
                  position: "absolute",
                  zIndex: 50,
                  left: "50%",
                  transform: "translateX(-50%)",
                  width: "100%",
                  height: "100%",
                  maxWidth: width,
                  top: 0
                }}
              >
                <div
                  style={{
                    maxWidth: width
                  }}
                  className="alert-on-top"
                  role="alert"
                >
                  {this.state.instruction}
                </div>
                <div style={{ clear: "both" }}></div>
                <div className="take-photo-actions">
                  <button
                    onClick={this.takePhoto}
                    className="take-photo-actions__btn"
                  >
                    <FaCamera />
                    Wykonaj zdjęcie
                  </button>
                  <button
                    onClick={this.skipPhoto}
                    className="take-photo-actions__btn"
                  >
                    <FaTimesCircle />
                    Pomiń
                  </button>
                </div>
              </div>
            )} */}
            <div
              style={{
                position: "absolute",
                zIndex: 9,
                top: 0,
                width: "100%",
                height: "100%"
              }}
            >
              <Webcam
                style={{
                  filter: "contrast(1)",
                  position: "absolute",
                  left: videoLeft,
                  top: videoTop,
                  width: videoWidth,
                  height: videoHeight,
                  opacity: this.props.isLoading ? 0 : 1,
                  transform: `scaleX(-1) scale(${videoScale})`
                }}
                audio={false}
                ref={this.webcamRef}
                screenshotQuality="1"
                screenshotFormat="image/jpeg"
                imageSmoothing={true}
                //   stream={this.props.context.stream}
              />
            </div>
            {this.props.detectedFaceShape && (
              <div
                style={{
                  color: "#8082ff",
                  zIndex: 50,
                  position: "absolute",
                  bottom: "1px",
                  right: "4px"
                }}
              >
                Wykryta twarz: {this.props.detectedFaceShape || ""}
              </div>
            )}
            {this.state.camera && this.state.camera.resolution && (
              <div
                style={{
                  color: "#8082ff",
                  zIndex: 50,
                  position: "absolute",
                  bottom: "1px",
                  left: "4px",
                  fontSize: "10px"
                }}
              >
                {this.state.camera.resolution.width} x{" "}
                {this.state.camera.resolution.height}
              </div>
            )}
          </div>
        </div>
        {/* Debug iframe */}
        <div id="debug"></div>
      </>
    );
  }
}

export default Video;

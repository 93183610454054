import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";

class Footer extends React.Component {
  render() {
    const { token } = this.props;
    // const { reset, copy } = allowActions ?? { reset: true, copy: true };

    const frontVersion = `${process.env.REACT_APP_VERSION}`;

    var debugData = [
      frontVersion ? "frontVersion: " + frontVersion : null,
      token ? "vmSession: " + token : null
    ];

    var debugData = debugData.filter(function (el) {
      return el !== null;
    });

    const copyDebugData = () => {
      navigator.clipboard.writeText(debugData.join("\r\n"));
    };

    return (
      <footer className="app-footer">
        {frontVersion && `v. ${frontVersion} `}
        <button className="copy-log" onClick={copyDebugData}>
          <FontAwesomeIcon icon={faCopy} /> Copy debug info
        </button>
      </footer>
    );
  }
}

export default Footer;
